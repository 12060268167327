import { z } from "zod";

import DataTypes from "~data";

import { ContactChannel } from "./partials/ContactChannel";
import { CustomerSuccessRating } from "./partials/CustomerSuccessRating";
import { Product } from "./partials/Product";
import { UsageTrend, UsageTrendData } from "./partials/UsageTrend";
import { PlanLicenseType, PlanPeriod } from "./Plan";
import { Team } from "./Team";
import { UserBaseData } from "./User";

export const Company = z.object({
  id: z.custom<`c_${string}`>(),
  activeLicenses: z.number(),
  billingEmail: z.string().nullable(),
  contractAutoRenews: z.boolean().nullable(),
  contractTerminatesOn: z.coerce.date().nullable(),
  domains: z.array(z.string()),
  isCancelling: z.boolean(),
  isOnTrial: z.boolean(),
  isInstalled: z.boolean(),
  yearlyValue: z.number(),
  products: Product.array(),
  name: z.string(),
  notes: z.string(),
  phoneNumber: z.string().nullable(),
  primaryDomain: z.string().nullable(),
  subscribedAt: z.coerce.date().nullable(),
  trialConvertsAutomatically: z.boolean().nullable(),
  trialEndsAt: z.coerce.date().nullable(),
  trialStartedAt: z.coerce.date().nullable(),
  unsubscribedAt: z.coerce.date().nullable(),
  usageTrend: UsageTrend,
  usageTrendData: UsageTrendData,
  licenseType: PlanLicenseType.nullable(),
  planPeriod: PlanPeriod.nullable(),
  nextInvoiceAt: z.coerce.date().nullable(),
  subscriptionEndsAt: z.coerce.date().nullable(),
  licensesNeeded: z.number(),
  planIsTimeLimited: z.boolean(),
  monthlySessionTimeCreditHours: z.number().nullable(),
  teams: Team.pick({
    id: true,
    domain: true,
    yearlyValue: true,
    usageTrendData: true,
    membersCount: true,
    lastCustomerSuccessCheckupAt: true,
    lastCustomerSuccessCheckupRating: true,
    currentCustomerSuccessStage: true,
    installationStatus: true,
    type: true,
  }).array(),
  primaryTeam: Team.pick({ id: true, type: true }).nullable(),
  lastCustomerSuccessCheckupRating: CustomerSuccessRating.nullable(),
  lastHeardFromAt: z.coerce.date().nullable(),
  lastHeardFromChannel: ContactChannel.nullable(),
  lastContactedAt: z.coerce.date().nullable(),
  lastContactedChannel: ContactChannel.nullable(),
  slackChannelId: z.string().nullable(),
  slackChannelName: z.string().nullable(),
  lastSeenOn: z.coerce.date().nullable(),
  type: z.literal("company"),
  hqTags: z.array(z.custom<`ht_${string}`>()),
});

export type Company = z.infer<typeof Company>;

export const CompanyBaseData = Company.pick({
  id: true,
  name: true,
  domains: true,
  type: true,
});
export type CompanyBaseData = z.infer<typeof CompanyBaseData>;

export const CompanyLeadData = CompanyBaseData.merge(
  z.object({
    leadAssignedTo: UserBaseData.nullable(),
    leadListDetails: z.custom<DataTypes["Companies"]["leadListDetails"]>(),
  }),
);

export type CompanyLeadData = z.infer<typeof CompanyLeadData>;

export const CompanyAdminViewData = CompanyBaseData.merge(
  Company.pick({
    activeLicenses: true,
    billingEmail: true,
    contractAutoRenews: true,
    contractTerminatesOn: true,
    isCancelling: true,
    isOnTrial: true,
    yearlyValue: true,
    products: true,
    notes: true,
    phoneNumber: true,
    primaryDomain: true,
    subscribedAt: true,
    trialConvertsAutomatically: true,
    trialEndsAt: true,
    trialStartedAt: true,
    unsubscribedAt: true,
    usageTrend: true,
    isInstalled: true,
    usageTrendData: true,
    licensesNeeded: true,
    licenseType: true,
    teams: true,
    primaryTeam: true,
    planPeriod: true,
    subscriptionEndsAt: true,
    lastCustomerSuccessCheckupRating: true,
    nextInvoiceAt: true,
    planIsTimeLimited: true,
    monthlySessionTimeCreditHours: true,
    slackChannelId: true,
    slackChannelName: true,
    lastHeardFromAt: true,
    lastHeardFromChannel: true,
    lastContactedAt: true,
    lastContactedChannel: true,
    lastSeenOn: true,
    hqTags: true,
  }),
);
