import { useWindowSize } from "@react-hookz/web";
import Confetti from "react-confetti";

import { Product } from "../../data/models/partials/Product";
import ProductLogo from "../brand/ProductLogo";
import Link from "../ui/Link";

type Props = {
  children: React.ReactNode;
  success?: boolean;
  successMessage?: string;
  confettiOnSuccess?: boolean;
  product?: Product;
};

function Gradient() {
  return (
    <img
      src={new URL("img/gradient.png", import.meta.url).href}
      className="absolute left-0 top-0 w-full select-none dark:hidden"
      draggable="false"
    />
  );
}

export default function AuthLayout({ children, success, confettiOnSuccess, product }: Props) {
  confettiOnSuccess = confettiOnSuccess ?? true;
  const { width, height } = useWindowSize();
  return (
    <>
      {success && confettiOnSuccess && (
        <Confetti width={width} height={height} numberOfPieces={60} gravity={0.02} className="!z-50" />
      )}
      <div className="flex h-screen w-full flex-row bg-white dark:bg-gray-900">
        <div className="flex h-full flex-1 flex-col overflow-y-auto bg-gray-50 dark:bg-gray-900">
          <div className="mx-auto flex size-full max-w-[440px] flex-col items-start justify-between space-y-10 px-4 py-8">
            <Link to={["up.auth.login", { product }]}>
              <ProductLogo product={product} className="relative z-10 text-black dark:text-white" />
            </Link>
            <div className="relative z-10 flex w-full flex-col space-y-6">{children}</div>
            <div />
          </div>
          <Gradient />
        </div>
      </div>
    </>
  );
}
