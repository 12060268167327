import { exists, t as i18t, use } from "i18next";
import { initReactI18next } from "react-i18next";

export const LANGUAGES = {
  "en": "English",
  "it": "Italiano",
  "ja": "日本語",
  "es": "Español",
  "fr": "Français",
  "de": "Deutsch",
  "nl": "Nederlands",
  "pt-PT": "Português",
  "pt-BR": "Português (Brasil)",
  "ko": "한국어",
  "zh-CN": "中文",
} as const;

export const LOAD_LANGUAGE_FILES: Record<string, () => Promise<Record<string, string>>> = {
  // @ts-ignore
  "en": () => import("./locales/en.yml"),
  // @ts-ignore
  "it": () => import("./locales/it.yml"),
  // @ts-ignore
  "ja": () => import("./locales/ja.yml"),
  // @ts-ignore
  "es": () => import("./locales/es.yml"),
  // @ts-ignore
  "fr": () => import("./locales/fr.yml"),
  // @ts-ignore
  "de": () => import("./locales/de.yml"),
  // @ts-ignore
  "nl": () => import("./locales/nl.yml"),
  // @ts-ignore
  "pt-PT": () => import("./locales/pt-PT.yml"),
  // @ts-ignore
  "pt-BR": () => import("./locales/pt-BR.yml"),
  // @ts-ignore
  "ko": () => import("./locales/ko.yml"),
  // @ts-ignore
  "zh-CN": () => import("./locales/zh-CN.yml"),
} as const;

export async function initI18n(language = "en"): Promise<void> {
  const genericLanguage = language.split("-")[0]!;
  if (!(language in LOAD_LANGUAGE_FILES)) language = genericLanguage in LOAD_LANGUAGE_FILES ? genericLanguage : "en";
  const languageFiles: Record<string, string> = await LOAD_LANGUAGE_FILES[language]!();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  use(initReactI18next).init({
    fallbackLng: "en",
    lng: language,
    resources: {
      [language]: { translation: languageFiles },
    },
    interpolation: {
      prefix: "%{",
      suffix: "}",
      escapeValue: false,
    },
  });
}

type ExtractInterpolationKeys<S extends string> = S extends `${infer _Start}%{${infer Key}}${infer Rest}`
  ? Key | ExtractInterpolationKeys<Rest>
  : never;

type InterpolationOptions<S extends string> = Record<ExtractInterpolationKeys<S>, string | number>;

// Overloaded function definitions
export default function t<S extends string>(value: S extends `${string}%{${string}` ? never : S): string;
export default function t<S extends `${string}%{${string}`, T extends InterpolationOptions<S>>(
  value: S,
  options: T,
): string;
export default function t(value: string, options: Record<string, any> = {}): string {
  const key =
    "t_" +
    value
      .replace(/\?/g, "_q")
      .replace(/!/g, "_e")
      .replace(/\./g, "_d")
      .replace(/[^A-z0-9]+/g, "_")
      .replace(/(^_|_$)/g, "");

  if (!exists(key)) return value.replace(/%{(.+?)}/g, (_, interpolationKey) => options[interpolationKey]);

  return i18t(key, options);
}
