import { createContext, useCallback, useContext, useState } from "react";

import { NotificationObject } from "~core/notifications/transformer";
import DataTypes from "~data";

import { NotificationsListItem } from "../components/ui/NotificationList";
import { useApi, useApiData } from "../use/api";
import useRedirect from "../use/redirect";
import { useAuthentication } from "./AuthenticationProvider";
import { useModal } from "./ModalProvider";

const notificationsContext = createContext({
  notificationsCount: undefined as number | undefined,
  loadingMore: false,
  hasMore: false,
  notifications: [] as NotificationsListItem[],
  onLoadMore: async (before?: DataTypes["Notifications"]["id"]) => {},
  clearNotifications: () => {},
  onAction: async (notificationId: DataTypes["Notifications"]["id"], actionId: string) => {},
});

export const useNotifications = () => useContext(notificationsContext);

export function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuthentication();
  const { notificationsCount, makeSetter } = useApiData(
    "global.notifications.count.show",
    {
      product: undefined,
    },
    {
      enable: !!user && !location.pathname.startsWith("/embed"),
    },
  );
  const { call: callNotificationsList } = useApi("global.notifications.list");
  const { call: callAction } = useApi("global.notifications.actions.perform");
  const [notifications, setNotifications] = useState<NotificationObject[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const setNotificationsCount = makeSetter("notificationsCount");
  const { addModal } = useModal();
  const redirect = useRedirect();

  const onLoadMore = useCallback(
    async (beforeNotificationId?: DataTypes["Notifications"]["id"]) => {
      setLoadingMore(true);
      const { notifications, hasMore } = await callNotificationsList({ beforeNotificationId });
      setNotifications((existingNotifications) => {
        const exsitingIds = existingNotifications.map((notification) => notification.id);
        if (beforeNotificationId) {
          return [
            ...existingNotifications,
            ...notifications.filter((notification) => !exsitingIds.includes(notification.id)),
          ];
        }

        return notifications;
      });
      setHasMore(hasMore);
      setLoadingMore(false);
    },
    [callNotificationsList],
  );

  const clearNotifications = useCallback(() => {
    setNotifications([]);
    setNotificationsCount((_) => 0);
  }, [setNotificationsCount]);

  const onAction = useCallback(
    async (notificationId: DataTypes["Notifications"]["id"], actionId: string) => {
      const notification = notifications.find((notification) => notification.id === notificationId)!;
      const confirmation = notification.confirmation?.[actionId];
      let confirmed = true;

      if (confirmation) {
        confirmed = await new Promise<boolean>((resolve) => {
          addModal({
            type: confirmation.danger ? "warning" : "info",
            title: "Confirmation",
            content: confirmation.message,
            confirmText: confirmation.confirmText,
            cancelText: confirmation.alternative?.text ?? "Cancel",
            onClick: resolve,
          });
        });

        if (!confirmed && !confirmation.alternative) return;
      }
      setNotifications((notifications) => {
        return notifications.map((notification) => {
          if (notification.id === notificationId) {
            return {
              ...notification,
              actionable: false,
            };
          }
          return notification;
        });
      });
      const result = await callAction({
        notificationId,
        actionId: confirmed ? actionId : confirmation!.alternative!.onClick,
      });
      if (result.success) {
        if (result.redirectUrl !== false) redirect(result.redirectUrl);
      } else {
        addModal({
          type: "warning",
          title: "Error",
          content: "There was an error performing the action. Please try again.",
        });
      }
    },
    [callAction, addModal, notifications, redirect],
  );

  return (
    <notificationsContext.Provider
      value={{
        notificationsCount,
        hasMore,
        loadingMore,
        onAction,
        onLoadMore,
        notifications,
        clearNotifications,
      }}
    >
      {children}
    </notificationsContext.Provider>
  );
}
