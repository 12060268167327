import { useEffect } from "react";

export function useGoogleTagManager({ userId, baa }: { userId?: string | null; baa?: boolean | null }) {
  useEffect(() => {
    (window as any).userId = userId;
    (window as any).baa = baa;
  }, [userId, baa]);

  useEffect(() => {
    if (typeof userId !== "undefined") addGoogleTagManager();
  }, [userId]);
}

function addGoogleTagManager() {
  if (process.env.NODE_ENV !== "production") return;
  const w = window as any;
  w["dataLayer"] = w["dataLayer"] || []; // eslint-disable-line @typescript-eslint/strict-boolean-expressions
  w["dataLayer"].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
  var f = document.getElementsByTagName("script")[0]!,
    j = document.createElement("script"),
    dl = "dataLayer" != "dataLayer" ? "&l=" + "dataLayer" : "";
  j.async = true;
  j.src = "//www.googletagmanager.com/gtm.js?id=GTM-KRWCSL6" + dl;
  f.parentNode!.insertBefore(j, f);
}
