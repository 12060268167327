import { lazy, LazyExoticComponent, ReactComponentElement } from "react";

import { TeamMembership } from "./data/models/TeamMembership";
import { getRouteOrigin, path, PathParameters } from "./paths";
import { NotFound } from "./router";

type PermissionKeys = keyof TeamMembership["permissions"];
type RouteAuthType = "guest" | "user" | "owner" | "admin" | PermissionKeys;

export type RouteType = {
  auth?: RouteAuthType;
  bypassAuthForAdmin?: boolean;
  component: LazyExoticComponent<() => ReactComponentElement<any>> | typeof NotFound;
  children?: Array<[PathParameters, RouteType]>;
};

const routeList: Array<[PathParameters, RouteType]> = [
  [
    ["up.root"],
    {
      component: lazy(() => import("./pages/Root")),
    },
  ],
  [
    ["up.auth.login", {}],
    {
      auth: "guest",
      component: lazy(() => import("./pages/auth/LogIn")),
    },
  ],
  [
    ["up.auth.signUp", {}],
    {
      auth: "guest",
      component: lazy(() => import("./pages/auth/SignUp")),
    },
  ],
  [
    [
      "up.auth.join",
      {
        teamDomain: ":teamDomain",
        teamUniqueIdentifier: ":teamUniqueIdentifier",
      },
    ],
    {
      auth: "guest",
      component: lazy(() => import("./pages/auth/JoinTeam")),
    },
  ],
  [
    ["up.auth.mfa.enable"],
    {
      component: lazy(() => import("./pages/auth/EnableMfa")),
      auth: "user",
    },
  ],
  [
    ["up.auth.invites.show", { inviteToken: ":inviteToken" }],
    {
      component: lazy(() => import("./pages/auth/Invites")),
    },
  ],
  [
    ["up.notifications.confirmation"],
    {
      component: lazy(() => import("./pages/auth/NotificationConfirmation")),
    },
  ],
  [
    ["up.shortCuts.purchase"],
    {
      auth: "user",
      component: lazy(() => import("./pages/onboarding/PurchaseRedirect")),
    },
  ],
  [
    ["up.shortCuts.install"],
    {
      auth: "user",
      component: lazy(() => import("./pages/onboarding/InstallRedirect")),
    },
  ],
  [
    ["up.onboarding", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/onboarding/Onboarding")),
      children: [
        [
          ["up.onboarding.questions", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/onboarding/steps/Questions")),
            auth: "owner",
          },
        ],
        [
          ["up.onboarding.bookCall", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/onboarding/steps/BookCall")),
            auth: "owner",
          },
        ],
        [
          ["up.onboarding.inviteTeam", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/onboarding/steps/InviteTeam")),
            auth: "canManageUsers",
          },
        ],
        [
          ["up.onboarding.purchase", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/onboarding/steps/Purchase")),
            auth: "canPurchase",
          },
        ],
        [
          ["up.onboarding.install", { teamDomain: ":teamDomain", teamUniqueIdentifier: ":teamUniqueIdentifier" }],
          {
            component: lazy(() => import("./pages/onboarding/steps/Install")),
          },
        ],
      ],
    },
  ],
  [
    ["up.invoice.showFailed", { invoiceId: ":invoiceId" }],
    {
      component: lazy(() => import("./pages/onboarding/PaymentFailed")),
    },
  ],
  [
    ["hs.extension.welcome"],
    {
      component: lazy(() => import("./pages/helloscreen/extension/Welcome")),
    },
  ],
  [
    ["hs.visitorsList", {}],
    {
      component: lazy(() => import("./pages/helloscreen/VisitorsList")),
      auth: "user",
    },
  ],
  [
    ["hs.visitorsList", { searchTerm: ":searchTerm" }],
    {
      component: lazy(() => import("./pages/helloscreen/VisitorsList")),
      auth: "user",
    },
  ],
  [
    ["hs.visitorsList", { searchTerm: "" }],
    {
      component: lazy(() => import("./pages/helloscreen/VisitorsList")),
      auth: "user",
    },
  ],
  [
    ["hs.visitorsList", { isEmbedded: true }],
    {
      component: lazy(() => import("./pages/helloscreen/VisitorsList")),
    },
  ],
  [
    ["hs.visitorsList", { isEmbedded: true, searchTerm: ":searchTerm" }],
    {
      component: lazy(() => import("./pages/helloscreen/VisitorsList")),
    },
  ],
  [
    ["hs.visitorsList", { isEmbedded: true, searchTerm: "" }],
    {
      component: lazy(() => import("./pages/helloscreen/VisitorsList")),
    },
  ],
  [
    ["hs.cobrowse", { visitorId: ":visitorId" }],
    {
      component: lazy(() => import("./pages/helloscreen/Cobrowse")),
      auth: "user",
    },
  ],
  [
    ["hs.cobrowse", { visitorId: ":visitorId", isEmbedded: true }],
    {
      component: lazy(() => import("./pages/helloscreen/Cobrowse")),
    },
  ],
  [
    ["hs.cobrowse", { onPremiseTeamUniqueIdentifier: ":onPremiseTeamUniqueIdentifier", visitorId: ":visitorId" }],
    {
      component: lazy(() => import("./pages/helloscreen/CobrowseOnpremRedirect")),
    },
  ],
  [
    ["uv.visitorsList", {}],
    {
      component: lazy(() => import("./pages/userview/VisitorsList")),
      auth: "user",
    },
  ],
  [
    ["uv.visitorsList", { searchTerm: ":searchTerm" }],
    {
      component: lazy(() => import("./pages/userview/VisitorsList")),
      auth: "user",
    },
  ],
  [
    ["uv.visitorsList", { searchTerm: "" }],
    {
      component: lazy(() => import("./pages/userview/VisitorsList")),
      auth: "user",
    },
  ],
  [
    ["uv.visitorsList", { isEmbedded: true }],
    {
      component: lazy(() => import("./pages/userview/VisitorsList")),
    },
  ],
  [
    ["uv.visitorsList", { isEmbedded: true, searchTerm: ":searchTerm" }],
    {
      component: lazy(() => import("./pages/userview/VisitorsList")),
    },
  ],
  [
    ["uv.visitorsList", { isEmbedded: true, searchTerm: "" }],
    {
      component: lazy(() => import("./pages/userview/VisitorsList")),
    },
  ],
  [
    ["uv.cobrowse", { visitorId: ":visitorId" }],
    {
      component: lazy(() => import("./pages/userview/Cobrowse")),
      auth: "user",
    },
  ],
  [
    ["uv.cobrowse", { visitorId: ":visitorId", isEmbedded: true }],
    {
      component: lazy(() => import("./pages/userview/Cobrowse")),
    },
  ],
  [
    ["uv.cobrowse", { onPremiseTeamUniqueIdentifier: ":onPremiseTeamUniqueIdentifier", visitorId: ":visitorId" }],
    {
      component: lazy(() => import("./pages/userview/CobrowseOnpremRedirect")),
    },
  ],
  [
    ["up.reporting.usage", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/reporting/Usage")),
      auth: "canViewReporting",
    },
  ],
  [
    ["up.visitorData", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/team/DeleteVisitorData")),
      auth: "canManageContent",
    },
  ],
  [
    ["up.settings.personal"],
    {
      component: lazy(() => import("./pages/settings/Personal")),
      auth: "user",
    },
  ],
  [
    ["up.settings.manageTeams", {}],
    {
      component: lazy(() => import("./pages/settings/ManageTeams")),
      auth: "user",
    },
  ],
  [
    ["up.members", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/team/members/Index")),
      auth: "canManageUsers",
      children: [
        [
          ["up.members", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/settings/team/members/MembersList")),
            auth: "canManageUsers",
          },
        ],
        [
          ["up.members.invites", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/settings/team/members/Invites")),
            auth: "canManageUsers",
          },
        ],
      ],
    },
  ],
  [
    ["up.history", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/activity/History")),
      auth: "canViewReporting",
    },
  ],
  [
    ["up.activity.activityLog", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/activity/ActivityLog")),
      auth: "canViewReporting",
    },
  ],
  [
    ["up.settings.general", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/General")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.settings.authTokens.new"],
    {
      component: lazy(() => import("./pages/auth/IntegrationAccessToken")),
      auth: "user",
    },
  ],
  // Further /settings/teams/:teamDomain/xyz routes need to be added here
  [
    ["up.integrations", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Index")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.zapier", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Zapier")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.zendesk", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Zendesk")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.freshchat", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Freshchat")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.twilioFlex", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/TwilioFlex")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.intercom.connect", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/integrations/IntercomConnect")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.front", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Front")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.front", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Front")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.intercom.settings", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Intercom")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.integrations.salesforce.settings", { teamDomain: ":teamDomain", integrationId: ":integrationId" }],
    {
      component: lazy(() => import("./pages/settings/integrations/Salesforce")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.settings.general", { teamDomain: ":teamDomain", section: ":section" }],
    {
      component: lazy(() => import("./pages/settings/General")),
      auth: "canAccessSettings",
    },
  ],
  [
    ["up.settings.billing", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/settings/billing/Index")),
      auth: "canManageBilling",
      children: [
        [
          ["up.settings.billing", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/settings/billing/Billing")),
            auth: "canManageBilling",
          },
        ],
        [
          ["up.settings.billing.sessionCredit", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/settings/billing/SessionCredit")),
            auth: "canManageBilling",
          },
        ],
        [
          ["up.settings.billing.subaccounts", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/settings/billing/Subaccounts")),
            auth: "canManageBilling",
          },
        ],
        [
          ["up.settings.billing.history", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/settings/billing/History")),
            auth: "canManageBilling",
          },
        ],
      ],
    },
  ],
  [
    ["co.root"],
    {
      component: lazy(() => import("./pages/cobrowsingapi/Redirect")),
      auth: "user",
    },
  ],
  [
    ["co.cobrowse", { visitorId: ":visitorId" }],
    {
      component: lazy(() => import("./pages/cobrowsingapi/CobrowseInstructions")),
      auth: "user",
    },
  ],
  [
    ["co.dashboard", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/cobrowsingapi/Dashboard")),
      auth: "canAccessSettings",
      children: [
        [
          ["co.dashboard.restKeys", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/cobrowsingapi/dashboard/RestKeys")),
          },
        ],
        [
          ["co.dashboard.requestHistory", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/cobrowsingapi/dashboard/RequestHistory")),
          },
        ],
        [
          ["co.dashboard.webhookHistory", { teamDomain: ":teamDomain" }],
          {
            component: lazy(() => import("./pages/cobrowsingapi/dashboard/WebhookHistory")),
          },
        ],
      ],
    },
  ],
  [
    ["hq.root"],
    {
      component: lazy(() => import("./pages/hq/Root")),
      auth: "admin",
    },
  ],
  [
    ["hq.emailRedirect"],
    {
      component: lazy(() => import("./pages/hq/EmailRedirect")),
      auth: "admin",
    },
  ],
  [
    ["hq.accounts.index", {}],
    {
      component: lazy(() => import("./pages/hq/accounts/Accounts")),
      auth: "admin",
    },
  ],
  [
    ["hq.accounts.show", { companyId: ":companyId" }],
    {
      component: lazy(() => import("./pages/hq/accounts/Show")),
      auth: "admin",
      children: [
        [
          ["hq.accounts.show", { companyId: ":companyId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/Overview")),
          },
        ],
        [
          ["hq.accounts.show.team", { companyId: ":companyId", teamId: ":teamId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/Team")),
          },
        ],
        [
          ["hq.accounts.show.members", { companyId: ":companyId", teamId: ":teamId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/Members")),
          },
        ],
        [
          ["hq.accounts.show.usage", { companyId: ":companyId", teamId: ":teamId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/Usage")),
          },
        ],
        [
          ["hq.accounts.show.emails", { companyId: ":companyId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/Emails")),
          },
        ],
        [
          ["hq.accounts.show.contacts", { companyId: ":companyId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/Contacts")),
          },
        ],
        [
          ["hq.accounts.show.companyDocuments", { companyId: ":companyId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/CompanyDocuments")),
          },
        ],
        [
          ["hq.accounts.show.activityLog", { companyId: ":companyId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/ActivityLog")),
          },
        ],
        [
          ["hq.accounts.show.charges", { companyId: ":companyId", teamId: ":teamId" }],
          {
            component: lazy(() => import("./pages/hq/accounts/show/ChargesAndInvoices")),
          },
        ],
      ],
    },
  ],
  [
    ["hq.customerSuccess.index"],
    {
      component: lazy(() => import("./pages/hq/cs/Index")),
      auth: "admin",
    },
  ],
  [
    ["hq.customerSuccess.list", { stage: ":stage" }],
    {
      component: lazy(() => import("./pages/hq/cs/List")),
      auth: "admin",
    },
  ],
  [
    ["hq.sales.index"],
    {
      component: lazy(() => import("./pages/hq/sales/Index")),
      auth: "admin",
    },
  ],
  [
    ["hq.sales.activities"],
    {
      component: lazy(() => import("./pages/hq/sales/Activities")),
      auth: "admin",
    },
  ],
  [
    ["hq.sales.opportunities.index"],
    {
      component: lazy(() => import("./pages/hq/sales/opportunities/Index")),
      auth: "admin",
    },
  ],
  [
    ["hq.sales.opportunities.show", { opportunityId: ":opportunityId" }],
    {
      component: lazy(() => import("./pages/hq/sales/opportunities/Show")),
      auth: "admin",
    },
  ],
  [
    ["hq.sales.tasks"],
    {
      component: lazy(() => import("./pages/hq/sales/Tasks")),
      auth: "admin",
    },
  ],
  [
    ["hq.sales.leads"],
    {
      component: lazy(() => import("./pages/hq/sales/LeadList")),
      auth: "admin",
    },
  ],
  [
    ["hq.quotes.index"],
    {
      component: lazy(() => import("./pages/hq/quotes/Index")),
      auth: "admin",
    },
  ],
  [
    ["hq.quotes.show", { quotePublicKey: ":quotePublicKey" }],
    {
      component: lazy(() => import("./pages/hq/quotes/Show")),
      auth: "admin",
    },
  ],
  [
    ["up.quotes.show", { quotePublicKey: ":quotePublicKey" }],
    {
      component: lazy(() => import("./pages/quotes/Show")),
    },
  ],
  [
    ["hq.quotes.transfer.new", { quotePublicKey: ":quotePublicKey" }],
    {
      component: lazy(() => import("./pages/hq/quotes/Transfer")),
      auth: "admin",
    },
  ],
  [
    ["hq.users.show", { userId: ":userId" }],
    {
      component: lazy(() => import("./pages/hq/users/Show")),
      auth: "admin",
    },
  ],
  [
    ["ld.root"],
    {
      component: lazy(() => import("./pages/livedocument/user/Root")),
      auth: "user",
    },
  ],
  [
    ["ld.links.list", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/livedocument/user/links/List")),
      auth: "user",
    },
  ],
  [
    ["ld.links.create", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/livedocument/user/links/Create")),
      auth: "user",
    },
  ],
  [
    ["ld.templates.index", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/livedocument/user/templates/Index")),
      auth: "user",
    },
  ],
  [
    ["ld.templates.create", { teamDomain: ":teamDomain" }],
    {
      component: lazy(() => import("./pages/livedocument/user/templates/Create")),
      auth: "user",
    },
  ],
  [
    ["ld.templates.show", { teamDomain: ":teamDomain", templateId: ":templateId" }],
    {
      component: lazy(() => import("./pages/livedocument/user/templates/Show")),
      auth: "user",
    },
  ],
  [
    ["ld.links.show", { linkSlug: ":linkSlug" }],
    {
      component: lazy(() => import("./pages/livedocument/ShowLink")),
    },
  ],
  [
    ["ld.links.show", { linkSlug: ":linkSlug", section: ":section" } as any],
    {
      component: lazy(() => import("./pages/livedocument/ShowLink")),
    },
  ],
  [
    ["hq.globalActivityLog", {}],
    {
      component: lazy(() => import("./pages/hq/reporting/GlobalActivityLog")),
      auth: "admin",
    },
  ],
  [
    ["hq.debug.show", { visitorId: ":visitorId" }],
    {
      component: lazy(() => import("./pages/hq/Debug")),
      auth: "admin",
    },
  ],
  [
    ["hs.extension.view", { sessionCode: ":sessionCode" }], // This has to be placed last as it's a catch-all route
    {
      component: lazy(() => import("./pages/helloscreen/extension/View")),
    },
  ],
];

export function getRoutes(): Array<[string, RouteType]> {
  return routeList
    .filter(([path, route]: [PathParameters, RouteType]) => {
      return getRouteOrigin(path[0]) === location.origin;
    })
    .map(([pathParam, route]: [PathParameters, RouteType]) => {
      return [path(...pathParam).split("?")[0]!, route];
    });
}
