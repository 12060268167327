import { useEffect } from "react";

export default function useIntercom({
  enable,
  name,
  email,
  hashedEmail,
}: {
  enable?: boolean;
  name?: string;
  email?: string;
  hashedEmail?: string;
}) {
  useEffect(() => {
    if (!enable || process.env.NODE_ENV !== "production") return;

    (window as any).intercomSettings = {
      app_id: "jjuq5mvv",
      email: email ?? null,
      name: name ?? null,
      user_hash: hashedEmail ?? null,
      hide_default_launcher: true,
    };

    addIntercom();
  }, [hashedEmail, email, name, enable]);
}

function addIntercom() {
  const w = window as any;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [] as any[];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/jjuq5mvv";
      const x = d.getElementsByTagName("script")[0]!;
      x.parentNode!.insertBefore(s, x);
    };
    if (d.readyState === "complete") l();
    else if (typeof w.attachEvent === "function") w.attachEvent("onload", l);
    else w.addEventListener("load", l, false);
  }
}
