import { Description, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, useRef } from "react";

import t from "../../i18n";
import { useModal } from "../../providers/ModalProvider";
import Button from "./Button";

type Props = {
  title: string;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  disappearing?: boolean;
  loading?: "confirm" | "cancel";
  defaultBehavior?: "confirm" | "cancel" | "none";
  type?: "warning" | "success" | "info";
  onClick?: (accept: boolean) => void;
};

export default function Modal(props: Props) {
  const type = props.type ?? "info";
  const buttonColor = type === "warning" ? "red" : type === "success" ? "green" : "black";
  const confirmText = props.confirmText ?? t("Ok");
  const defaultBehavior = props.defaultBehavior ?? "cancel";

  const cancelButtonRef = useRef(null);
  const confirmButtonRef = useRef(null);
  const titleRef = useRef(null);
  const { removeModal } = useModal();

  function onCancel() {
    props.onClick?.(false);
  }

  function onConfirm() {
    props.onClick?.(true);
  }

  function onClose() {
    switch (defaultBehavior) {
      case "confirm":
        onConfirm();
        break;
      case "cancel":
        onCancel();
        break;
      case "none":
        removeModal();
        break;
    }
  }

  const initialFocus =
    defaultBehavior === "confirm" ? confirmButtonRef : defaultBehavior === "cancel" ? cancelButtonRef : titleRef;

  return (
    <Transition show={!props.disappearing} as={Fragment}>
      <Dialog as="div" className="z-70 relative" initialFocus={initialFocus} onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                data-role="modal"
                className="relative w-full overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-lg
                  dark:bg-neutral-800"
              >
                <div className="space-y-2 p-5 text-center text-slate-700 dark:text-slate-100">
                  <DialogTitle className="text-xl font-semibold" ref={titleRef}>
                    {props.title}
                  </DialogTitle>
                  {typeof props.children === "string" ? <Description>{props.children}</Description> : props.children}
                </div>
                <div className="flex flex-col justify-items-stretch gap-3 bg-neutral-100 p-3 sm:flex-row-reverse dark:bg-black/60">
                  <Button
                    color={buttonColor}
                    className="w-full"
                    onClick={onConfirm}
                    loading={props.loading === "confirm"}
                    disabled={props.loading === "cancel"}
                    ref={confirmButtonRef}
                  >
                    {confirmText}
                  </Button>
                  {props.cancelText && (
                    <Button
                      className="w-full"
                      ref={cancelButtonRef}
                      onClick={onCancel}
                      loading={props.loading === "cancel"}
                      disabled={props.loading === "confirm"}
                      variant={buttonColor === "black" ? "secondary" : "primary"}
                    >
                      {props.cancelText}
                    </Button>
                  )}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
