import { forwardRef } from "react";
import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";

import type { PathParameters } from "../../paths";
import { path, serverResponsePaths } from "../../paths";

export type DirectLink = `https://${string}` | `http://${string}` | `mailto:${string}` | `tel:${string}` | `/${string}`;
export type LinkToParam = PathParameters | Readonly<PathParameters> | DirectLink;

type LinkProps = {
  to: LinkToParam;
  children?: React.ReactNode;
  newTab?: boolean;
  untrusted?: boolean;
} & JSX.IntrinsicElements["a"];

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, children, newTab, untrusted, ...props }: LinkProps, ref): JSX.Element => {
    const needsServerResponse =
      typeof to === "string" || Object.keys(serverResponsePaths).includes(to[0] as keyof typeof serverResponsePaths);
    const destination = typeof to === "string" ? to : path(...to);
    // Use <a> for external links and legacy links
    const LinkElement = needsServerResponse || !destination.startsWith("/") ? "a" : RouterLink;
    const anchorProps = LinkElement === "a" ? { href: destination } : {};

    newTab ??= false;
    untrusted ??= false;

    return (
      <LinkElement
        {...props}
        ref={ref}
        {...anchorProps}
        to={destination}
        target={newTab ? "_blank" : undefined}
        rel={newTab && untrusted ? "noopener noreferrer" : undefined}
      >
        {children}
      </LinkElement>
    );
  },
);

export default Link;

type NavLinkProps = {
  to: LinkToParam;
  children?: React.ReactNode;
  newTab?: boolean;
  className?: (props: { isActive: boolean }) => string;
} & Omit<JSX.IntrinsicElements["a"], "className">;

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ to, children, newTab, className, ...props }: NavLinkProps, ref): JSX.Element => {
    // Until the entire app is migrated, we  use <a> so that the browser reloads
    const isLegacy =
      typeof to === "string" || Object.keys(serverResponsePaths).includes(to[0] as keyof typeof serverResponsePaths);
    const destination = typeof to === "string" ? to : path(...to);
    // Use <a> for external links and legacy links
    const LinkElement = isLegacy || !destination.startsWith("/") ? "a" : RouterNavLink;

    newTab ??= false;

    if (LinkElement === "a") {
      return (
        <LinkElement
          {...props}
          ref={ref}
          href={destination}
          target={newTab ? "_blank" : undefined}
          className={className?.({ isActive: false })}
        >
          {children}
        </LinkElement>
      );
    }

    return (
      <LinkElement
        {...props}
        ref={ref}
        to={destination}
        target={newTab ? "_blank" : undefined}
        className={className}
        end
      >
        {children}
      </LinkElement>
    );
  },
);
