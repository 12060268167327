import i18next from "i18next";

export default class Day implements DayInterface {
  public year: number;
  public month: number;
  public day: number;

  constructor(date: Day | Date | `${number}-${number}-${number}` = new Date()) {
    if (typeof date === "string") {
      const [year, month, day] = date.split("-").map(Number);
      this.year = year!;
      this.month = month!;
      this.day = day!;
    } else if (date instanceof Day) {
      this.year = date.year;
      this.month = date.month;
      this.day = date.day;
    } else {
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.day = date.getDate();
    }
  }

  public getTime() {
    return this.beginningOfDay().getTime();
  }

  public isBefore(date: Day) {
    return this.getTime() < date.getTime();
  }

  public toLocaleDateString(locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions | undefined) {
    return this.beginningOfDay().toLocaleDateString(locales ?? i18next.languages[0] ?? "en-US", options);
  }

  public isAfter(date: Day) {
    return this.getTime() > date.getTime();
  }

  public isSameDay(date: Day) {
    return this.toString() === date.toString();
  }

  public add(count: number, unit: "days" | "months" | "years"): DayInterface {
    const date = this.beginningOfDay();
    switch (unit) {
      case "days":
        date.setDate(date.getDate() + count);
        break;
      case "months":
        date.setMonth(date.getMonth() + count);
        break;
      case "years":
        date.setFullYear(date.getFullYear() + count);
        break;
    }
    return new Day(date);
  }

  public subtract(count: number, unit: "days" | "months" | "years") {
    return this.add(-count, unit);
  }

  public toString(): `${number}-${number}-${number}` {
    let month = String(this.month);
    if (month.length === 1) month = `0${month}`;

    let day = String(this.day);
    if (day.length === 1) day = `0${day}`;

    return `${this.year}-${month}-${day}` as `${number}-${number}-${number}`;
  }

  public toJSON() {
    return this.toString();
  }

  public beginningOfDay() {
    return new Date(this.year, this.month - 1, this.day);
  }

  public endOfDay() {
    return new Date(this.year, this.month - 1, this.day, 23, 59, 59, 999);
  }

  public utcEndOfDay() {
    return new Date(Date.UTC(this.year, this.month - 1, this.day, 23, 59, 59, 999));
  }

  public utcBeginningOfDay() {
    return new Date(Date.UTC(this.year, this.month - 1, this.day));
  }

  /**
   * Returns the end of the day in global time.
   * The end of the day is defined as midnight in UTC−12:00.
   *
   * @returns {Date} A Date object representing the end of the day.
   */
  public globalEndOfDay() {
    return new Date(Date.UTC(this.year, this.month - 1, this.day) + 38 * 60 * 60 * 1000 - 1);
  }

  /**
   * Returns the start of the day in global time.
   * The start of the day is defined as 12am in UTC+14:00.
   *
   * @returns {Date} A Date object representing the start of the day.
   */
  public globalBeginningOfDay() {
    return new Date(Date.UTC(this.year, this.month - 1, this.day) - 12 * 60 * 60 * 1000);
  }

  public at(hour: number, minute: number, second: number = 0) {
    return new Date(this.year, this.month - 1, this.day, hour, minute, second);
  }

  public atTimezone(timeZone: string, hour: number, minute: number, second: number = 0): Date {
    const date = new Date(Date.UTC(this.year, this.month - 1, this.day, hour, minute, second));

    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timeZone }));
    const offset = utcDate.getTime() - tzDate.getTime();

    date.setTime(date.getTime() + offset);

    return date;
  }

  public beginningOfMonth() {
    return new Date(this.year, this.month - 1, 1);
  }

  public endOfMonth() {
    return new Date(this.year, this.month, 1);
  }

  public beginningOfYear() {
    return new Date(this.year, 0, 1);
  }

  public endOfYear() {
    return new Date(this.year + 1, 0, 1);
  }

  public beginningOfWeek() {
    const date = this.beginningOfDay();
    date.setDate(date.getDate() - date.getDay() + 1);
    return date;
  }

  public endOfWeek() {
    const date = this.endOfDay();
    date.setDate(date.getDate() + (7 - date.getDay()));
    return date;
  }

  public beginningOfQuarter() {
    const date = this.beginningOfMonth();
    date.setMonth(date.getMonth() - (date.getMonth() % 3));
    return date;
  }

  public endOfQuarter() {
    const date = this.endOfMonth();
    date.setMonth(date.getMonth() + (3 - (date.getMonth() % 3)));
    return date;
  }

  public daysTo(date: Day) {
    return Math.floor((date.getTime() - this.getTime()) / (1000 * 60 * 60 * 24));
  }

  public valueOf() {
    return this.getTime();
  }
}
