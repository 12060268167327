import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Bars3Icon, EllipsisVerticalIcon, Squares2X2Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BellIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useWindowSize } from "@react-hookz/web";
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";

import { GamificationCurrentGame } from "~core/gamification/userGamification";
import DataTypes from "~data";

import { Product } from "../../data/models/partials/Product";
import { productName } from "../../formatters/billing";
import t from "../../i18n";
import { useSlideOver } from "../../providers/SlideOverProvider";
import { classNames, presence } from "../../utils";
import LogoSquare from "../brand/LogoSquare";
import ProductLogo from "../brand/ProductLogo";
import Button from "../ui/Button";
import ButtonLink from "../ui/ButtonLink";
import Dropdown from "../ui/Dropdown";
import Link, { LinkToParam } from "../ui/Link";
import NotificationList, { NotificationsListItem } from "../ui/NotificationList";
import Gamification from "./partials/application/sidebar-prompts/Gamification";

type NavItem = {
  id: string;
  name: string;
  to: LinkToParam;
  icon?: React.ComponentType<any>;
  current: boolean;
  count?: number;
  countColor?: "red";
};

type NotificationsProps = {
  onAction: (notificationId: DataTypes["Notifications"]["id"], actionId: string) => Promise<void>;
  notificationsCount: number | undefined;
  notifications: NotificationsListItem[] | undefined;
  hasMore: boolean | undefined;
  onLoadMore: (beforeId?: DataTypes["Notifications"]["id"]) => void;
  loadingMore: boolean;
  clearNotifications: () => void;
};

type Props = {
  currentTitle?: string;
  children: React.ReactNode;
  product?: Product;
  primaryNavigation: {
    items: NavItem[];
  };
  secondaryNavigation?: {
    title: string;
    items: NavItem[];
  };
  auth: {
    email: string;
  };
  gamification?: GamificationCurrentGame | null;
  availableProducts?: Set<Product>;
  hiddenSidebar?: boolean;
  fixedHeight?: boolean;
  topBar?: {
    content: string;
    cta?: {
      label: string;
    } & ({ onClick: () => void } | { to: LinkToParam });
  };
  onLogout: () => void;
  notifications?: NotificationsProps;
};

export default function ApplicationLayout(props: Props) {
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = process.env.STORYBOOK ? { pathname: "/" } : useLocation();

  useEffect(() => {
    setDesktopSidebarOpen(false);
  }, [pathname]);

  return (
    <>
      <div
        className={classNames(
          "bg-gray-50 dark:bg-gray-900 flex flex-col",
          props.fixedHeight ? "h-screen" : "min-h-screen",
        )}
      >
        {props.topBar && <TopBar {...props} topBar={props.topBar} />}
        <MobileNavigation {...props} />
        <DesktopNavigation
          {...props}
          desktopSideBarOpen={desktopSidebarOpen}
          setDesktopSidebarOpen={setDesktopSidebarOpen}
        />
        <main
          className={classNames(
            `divide-y divide-gray-200 dark:divide-gray-800 lg:transition-all ease-linear duration-300 relative flex-1 flex flex-col`,
            props.hiddenSidebar ? "lg:ml-10" : "lg:ml-72",
          )}
        >
          {props.children}
        </main>
      </div>
    </>
  );
}

function TopBar(props: Props & { topBar: Exclude<Props["topBar"], undefined> }) {
  return (
    <div
      className="bg-primary-500 dark:bg-primary-200 z-60 top-0 flex min-h-12 flex-col gap-2 overflow-auto p-2 text-center sm:px-3.5
        md:sticky md:flex-row md:items-center md:justify-between md:px-4 md:text-left"
    >
      <p className="flex-1 text-sm font-semibold text-white md:leading-6 dark:text-black">{props.topBar.content}</p>
      {props.topBar.cta &&
        ("to" in props.topBar.cta ? (
          <ButtonLink size="sm" to={props.topBar.cta.to}>
            {props.topBar.cta.label}
          </ButtonLink>
        ) : (
          <Button size="sm" onClick={props.topBar.cta.onClick}>
            {props.topBar.cta.label}
          </Button>
        ))}
    </div>
  );
}

function MobileNavigation(props: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  /* eslint-disable react-hooks/rules-of-hooks */
  const { pathname } = process.env.STORYBOOK ? { pathname: "/" } : useLocation();
  /* eslint-enable react-hooks/rules-of-hooks */
  const { addSlideOver } = useSlideOver();

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  const currentPageName = useMemo(() => {
    if (props.currentTitle) return props.currentTitle;

    const primaryName = props.primaryNavigation.items.filter((i) => i.current)[0]?.name;
    const secondaryName = props.secondaryNavigation?.items.filter((i) => i.current)[0]?.name;

    return secondaryName ? `${primaryName} » ${secondaryName}` : primaryName;
  }, [props.currentTitle, props.primaryNavigation.items, props.secondaryNavigation?.items]);

  return (
    <>
      <div className="sticky top-0 z-50 flex h-14 items-center gap-x-6 bg-white px-4 shadow-sm sm:px-6 lg:hidden dark:bg-gray-900">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden dark:text-gray-300"
          onClick={() => setSidebarOpen(true)}
          data-role="sidebar-toggle-mobile"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="size-6" aria-hidden="true" />
        </button>
        <div className="flex-1 truncate text-sm font-semibold leading-6 text-gray-900 dark:text-white">
          {currentPageName}
        </div>
        {props.notifications && <Notifications {...props.notifications} />}
        <Dropdown
          links={[
            { name: t("Account settings"), to: ["up.settings.personal"] },
            { name: t("Manage teams"), to: ["up.settings.manageTeams", {}] },
            { name: t("Support"), onClick: getSupport },
            { name: t("Sign out"), onClick: props.onLogout, className: "text-red-700" },
          ]}
        >
          <>
            <span className="sr-only">Your profile</span>
            <UserCircleIcon className="size-6 cursor-pointer text-slate-500 dark:text-gray-400" />
          </>
        </Dropdown>
      </div>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="z-60 relative lg:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="size-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2 dark:bg-gray-900">
                  <ProductSwitcher
                    product={props.product}
                    switchOptions={props.availableProducts}
                    notifications={props.notifications}
                  />
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          <NavigationItems items={props.primaryNavigation.items} />
                        </ul>
                      </li>
                      {props.secondaryNavigation && (
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            {props.secondaryNavigation.title}
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            <NavigationItems items={props.secondaryNavigation.items} />
                          </ul>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function DesktopNavigation(
  props: Props & { desktopSideBarOpen: boolean; setDesktopSidebarOpen: (open: boolean) => void },
) {
  const { hiddenSidebar, setDesktopSidebarOpen } = props;
  function openSidebar(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    evt.preventDefault();
    setDesktopSidebarOpen(true);
  }

  useEffect(() => {
    if (hiddenSidebar) setDesktopSidebarOpen(false);
  }, [hiddenSidebar, setDesktopSidebarOpen]);

  return (
    <>
      <Transition show={!props.hiddenSidebar} as={Fragment} key="visible-sidebar">
        <TransitionChild
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="!-translate-x-full"
        >
          <DesktopSidebar {...props} />
        </TransitionChild>
      </Transition>
      {props.hiddenSidebar && (
        <>
          <Transition show={props.desktopSideBarOpen} as={Fragment} key="hidden-sidebar--sidebar">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="!-translate-x-full"
            >
              <Dialog as={Fragment} onClose={props.setDesktopSidebarOpen}>
                <DesktopSidebar {...props} hiddenSidebar={false} className="dark:bg-slate-900" />
              </Dialog>
            </TransitionChild>
          </Transition>
          <Transition show={props.desktopSideBarOpen} as={Fragment} key="hidden-sidebar--toggle">
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 z-50 bg-gray-900/90" />
            </TransitionChild>
          </Transition>
        </>
      )}
      <a
        className={classNames(
          `hidden lg:flex fixed inset-y-0 w-10 items-start justify-center bg-gray-50 border-r border-gray-200 dark:border-gray-800
          dark:bg-gray-900 cursor-pointer pt-4 transition-all ease-linear duration-300`,
          props.topBar && "mt-12",
          !props.hiddenSidebar && "opacity-0",
        )}
        onClick={openSidebar}
        data-role="sidebar-toggle-desktop"
      >
        <span className="sr-only">Open sidebar</span>
        <LogoSquare height={24} className="text-slate-700 dark:text-white/70" />
      </a>
    </>
  );
}

const DesktopSidebar = forwardRef<HTMLDivElement, Props & { className?: string }>((props, ref) => {
  const { addSlideOver } = useSlideOver();

  return (
    <div
      className={classNames(
        "hidden fixed inset-y-0 z-50 lg:flex w-72 flex-col overflow-y-auto",
        props.topBar && "mt-12",
        props.hiddenSidebar && "-translate-x-full",
        props.className,
      )}
      ref={ref}
    >
      <div className="flex grow cursor-default flex-col gap-y-5 border-r border-gray-200 bg-white px-6 dark:border-gray-800 dark:bg-black/20">
        <ProductSwitcher
          product={props.product}
          switchOptions={props.availableProducts}
          notifications={props.notifications}
        />
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                <NavigationItems items={props.primaryNavigation.items} />
              </ul>
            </li>
            {props.secondaryNavigation && (
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">{props.secondaryNavigation.title}</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  <NavigationItems items={props.secondaryNavigation.items} />
                </ul>
              </li>
            )}
            <li className="mt-auto shrink-0">
              <Gamification currentProduct={props.product} game={presence(props.gamification)} />

              <div className="mb-3 flex items-center justify-between">
                <div className="w-full">
                  <p className="text-sm text-gray-500 dark:text-gray-400">{t("Signed in as")}</p>
                  <p className="truncate text-sm text-gray-900 dark:text-white">{props.auth.email}</p>
                </div>
                <Dropdown
                  links={[
                    { name: t("Account settings"), to: ["up.settings.personal"] },
                    { name: t("Manage teams"), to: ["up.settings.manageTeams", {}] },
                    { name: t("Support"), onClick: getSupport },
                    { name: t("Sign out"), onClick: props.onLogout, color: "red" },
                  ]}
                >
                  <div className="size-6 cursor-pointer rounded-full text-slate-500 hover:text-slate-900 dark:hover:text-slate-200">
                    <EllipsisVerticalIcon />
                  </div>
                </Dropdown>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
});

function NavigationItems({ items }: { items: NavItem[] }) {
  return (
    <>
      {items.map((item) => (
        <li key={item.name}>
          <Link
            to={item.to}
            className={classNames(
              item.current
                ? "bg-primary-100 dark:bg-gray-800 text-primary-600 dark:text-gray-200"
                : `text-gray-700 dark:text-gray-100 hover:text-primary-600 hover:bg-primary-100 dark:hover:text-gray-200
                  dark:hover:bg-gray-800`,
              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold truncate active:scale-95 transition-transform",
            )}
          >
            {item.icon ? (
              <item.icon
                className={classNames(
                  item.current
                    ? "text-primary-600 dark:text-gray-200"
                    : "text-gray-400 dark:text-gray-400 group-hover:text-primary-600 dark:group-hover:text-gray-200",
                  "h-6 w-6 shrink-0",
                )}
                aria-hidden="true"
              />
            ) : (
              <span
                className={classNames(
                  item.current
                    ? "text-primary-600 border-primary-600 dark:text-white dark:border-gray-700 dark:bg-gray-800"
                    : `text-gray-400 border-gray-200 dark:border-gray-600 group-hover:border-primary-600 group-hover:text-primary-600
                      dark:group-hover:border-gray-700 dark:group-hover:text-white`,
                  `flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-semibold bg-white
                  dark:bg-gray-900`,
                )}
              >
                {item.name[0]?.toUpperCase()}
              </span>
            )}
            {item.name}
            {typeof item.count === "number" ? (
              <span
                className={classNames(
                  "ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-xs leading-5 border",
                  item.countColor === "red"
                    ? "bg-red-100 dark:bg-red-700 text-red-800 dark:text-red-100 border-red-700/50"
                    : "bg-gray-100 dark:bg-gray-600 text-gray-600 dark:text-gray-100 border-gray-700/50",
                )}
                aria-hidden="true"
              >
                {item.count}
              </span>
            ) : null}
          </Link>
        </li>
      ))}
    </>
  );
}

function productRoot(product: Product | undefined): LinkToParam {
  return product
    ? {
        userview: ["uv.root"] as LinkToParam,
        helloscreen: ["hs.root"] as LinkToParam,
        cobrowsingapi: ["co.root"] as LinkToParam,
        livedocument: ["ld.root"] as LinkToParam,
      }[product]
    : ["up.root"];
}

function ProductSwitcher({
  product,
  switchOptions,
  notifications,
}: {
  product?: Product;
  switchOptions?: Set<Product>;
  notifications?: NotificationsProps;
}) {
  if (!switchOptions || switchOptions.size === 0) {
    return (
      <div className="mt-3 flex shrink-0 items-center justify-between">
        <Link to={productRoot(product)}>
          <ProductLogo product={product} className="text-black dark:text-white" />
        </Link>
        {notifications && <Notifications {...notifications} />}
      </div>
    );
  }

  const options = Array.from(switchOptions).map((p) => ({
    name: productName(p),
    to: productRoot(p),
  }));

  return (
    <div className="mt-3 flex shrink-0 items-center justify-between">
      <Link to={productRoot(product)}>
        <ProductLogo product={product} className="text-black dark:text-white" />
      </Link>
      <div className="flex items-center gap-3">
        <Dropdown links={options}>
          <div className="size-6 cursor-pointer rounded-full text-slate-500 hover:text-slate-900 dark:hover:text-slate-200">
            <Squares2X2Icon />
          </div>
        </Dropdown>
        {notifications && <Notifications {...notifications} />}
      </div>
    </div>
  );
}

function Notifications({
  notifications,
  onLoadMore,
  clearNotifications,
  ...props
}: NotificationsProps & { sidebarOpen?: boolean }) {
  const [position, setPosition] = useState<{ top: number; left: number } | { top: number; right: number }>({
    top: 0,
    left: 0,
  });
  const windowSize = useWindowSize();
  const buttonRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  useEffect(() => {
    if (!buttonRef.current) return;
    const rect = buttonRef.current.getBoundingClientRect();
    if (windowSize.width < 1024) {
      // lg:
      setPosition({ top: rect.bottom + 4, right: 0 });
      return;
    }

    setPosition({ top: rect.bottom + 4, left: rect.right });
  }, [windowSize, notificationsOpen]);

  useEffect(() => {
    setNotificationsOpen(false);
  }, [windowSize]);

  useEffect(() => {
    if (notificationsOpen) {
      clearNotifications();
      onLoadMore();
    }
  }, [notificationsOpen, onLoadMore, clearNotifications]);

  useEffect(() => {
    if (!notificationsOpen) return;

    const clickListener = (e: MouseEvent) => {
      if (
        !buttonRef.current?.contains(e.target as Node) &&
        !listRef.current?.querySelector('[data-role="notifications-list"]')?.contains(e.target as Node)
      )
        setNotificationsOpen(false);
    };

    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") setNotificationsOpen(false);
    };

    document.addEventListener("click", clickListener);
    document.addEventListener("keydown", keydownListener);

    return () => {
      document.removeEventListener("click", clickListener);
      document.removeEventListener("keydown", keydownListener);
    };
  }, [notificationsOpen]);

  const onReachEnd = useCallback(() => {
    if (!notifications) return onLoadMore();
    onLoadMore(notifications[notifications.length - 1]?.id);
  }, [onLoadMore, notifications]);

  return (
    <>
      <div className="relative cursor-pointer" ref={buttonRef} onClick={() => setNotificationsOpen((open) => !open)}>
        {typeof props.notificationsCount === "number" && props.notificationsCount > 0 && (
          <div className="absolute -right-0.5 -top-0.5 size-2 rounded-full bg-red-500 ring-2 ring-white dark:bg-red-400 dark:ring-gray-900" />
        )}
        <BellIcon className="size-5 cursor-pointer text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-200" />
      </div>
      {createPortal(
        <div className="z-60 fixed w-96 max-w-full" style={position} ref={listRef} key="notifications-container">
          <NotificationList
            notifications={notifications ?? []}
            hasMore={props.hasMore ?? false}
            loadingMore={props.loadingMore}
            onReachEnd={onReachEnd}
            onAction={props.onAction}
            open={notificationsOpen}
          />
        </div>,
        document.body,
      )}
    </>
  );
}

function getSupport() {
  if (typeof (window as any).Intercom === "function") return (window as any).Intercom("showNewMessage");

  window.open("mailto:support@upscope.com");
}
